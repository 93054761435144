export const frenchFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554204/main%20website/countries/Flag_of_France__1794_1815__1830_1974_.svg_p56bxt.png";
export const englandFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693498800/main%20website/countries/Flag_of_the_United_Kingdom__3-5_.svg_fam6mz.webp";

export const ciFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566931/main%20website/countries/Flag_of_Co%CC%82te_d_Ivoire.svg_uulepi.png";

export const beninFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566930/main%20website/countries/Flag_of_Benin.svg_rldt4h.png";

export const burkinaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566931/main%20website/countries/Flag_of_Burkina_Faso.svg_gpnhuv.png";

export const maliFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566931/main%20website/countries/Flag_of_Mali.svg_c38yhe.png";

export const togoFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566934/main%20website/countries/Flag_of_Togo.svg_o1x9av.png";

export const senegalFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566932/main%20website/countries/Flag_of_Senegal.svg_xdqldv.png";

export const camerounFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693566931/main%20website/countries/Flag_of_Cameroon.svg_nggdtt.png";

export const germanyFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554204/main%20website/countries/Flag_of_Germany.svg_qccjxi.png";

export const portugalFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554205/main%20website/countries/Flag_of_Portugal.svg_wk2cfb.png";

export const spainFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554209/main%20website/countries/Flag_of_Spain.svg_zhccaz.png";

export const belgiumFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554211/main%20website/countries/Flag_of_Belgium__civil_.svg_doq8gs.png";

export const usaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693498800/main%20website/countries/Flag_of_the_United_States.svg_shumd6.webp";

export const chinaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693498799/main%20website/countries/Flag_of_the_People_s_Republic_of_China.svg_qsepgq.png";

export const canadaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693498799/main%20website/countries/Flag_of_Canada__Pantone_.svg_pjvtep.webp";

export const uaeFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693498799/main%20website/countries/Flag_of_the_United_Arab_Emirates.svg_ryeflm.webp";

export const italyFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554209/main%20website/countries/Flag_of_Italy.svg_qyweqh.png";

export const croatiaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554211/main%20website/countries/Flag_of_Croatia.svg_flcizi.png";

export const austriaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554211/main%20website/countries/Flag_of_Austria.svg_pawiir.png";

export const finlandFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554203/main%20website/countries/Flag_of_Finland.svg_g533ua.png";

export const greeceFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554208/main%20website/countries/Flag_of_Greece.svg_jj1k5h.png";

export const netherlandFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554213/main%20website/countries/Flag_of_the_Netherlands.svg_vnp0ed.png";

export const luxemburgFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554203/main%20website/countries/Flag_of_Luxembourg.svg_e997ol.png";

export const lithuaniaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554203/main%20website/countries/Flag_of_Lithuania.svg_id7rgk.png";

export const lettoniaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554203/main%20website/countries/Flag_of_Latvia.svg_jqwr3s.png";

export const cyprusFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554203/main%20website/countries/Flag_of_Cyprus.svg_y5wrud.png";

export const estoniaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554203/main%20website/countries/Flag_of_Estonia.svg_da2i5r.png";

export const slovakiaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554208/main%20website/countries/Flag_of_Slovakia.svg_avyinc.png";

export const sloveniaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554209/main%20website/countries/Flag_of_Slovenia.svg_p4dfug.png";

export const maltaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1697554204/main%20website/countries/Flag_of_Malta__variant_.svg_fwrytd.png";

export const nigeriaFlag =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693498800/main%20website/countries/Flag_of_Nigeria.svg_azch9h.webp";

export const facebookLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469528/main%20website/website-2.0/Facebook_h9kwyf.png";

export const twitterLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469542/main%20website/website-2.0/Twiter_w0ybsk.png";
export const instagramLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469532/main%20website/website-2.0/Instagram_jnlzue.png";
export const linkedinLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469533/main%20website/website-2.0/LinkedIn_jqhtua.png";

export const payqinLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1671703030/main%20website/webflow-website/payqin_kj2wx9.webp";

export const pciLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699472678/main%20website/website-2.0/pci-logo_bmnhfb_kfna9t.png";

export const googlePLayLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1626280725/main%20website/Google-CTA_2x_iayzfn_x0u7up.png";

export const appleStoreLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1626280731/main%20website/iOS-CTA_2x_felwyr_lqv0dy.png";

export const expandDownImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469527/main%20website/website-2.0/expand_down_qsu84i.png";

export const expandUpImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699638614/main%20website/website-2.0/Expand_up_3x_liiy10.png";

export const closeFillImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469525/main%20website/website-2.0/close-fill_styzvt.png";

export const openFillImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469525/main%20website/website-2.0/add-line_mvuga0.png";

export const instantTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469531/main%20website/website-2.0/Icon_Instant_Transactions_n1csq0.png";

export const worldTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699981213/main%20website/website-2.0/Virtual_Card_cnqtl7.png";

export const personTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699981289/main%20website/website-2.0/person_h8yoc9.png";

export const pricingCardImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699971203/main%20website/website-2.0/carte_go9wz1.png";

export const moneyTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469529/main%20website/website-2.0/Group_37_hm9arw.png";

export const doubleMoneyTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469530/main%20website/website-2.0/Group_38_ckjat2.png";

export const payinTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469530/main%20website/website-2.0/Group_39_jka7he.png";

export const wifiTransacImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469531/main%20website/website-2.0/Group_40_jxmvhk.png";

export const confidentialDataImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469526/main%20website/website-2.0/Confidential_Data_cargtw.png";

export const virtualCardImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469544/main%20website/website-2.0/Virtual_Card_an5pxs.png";

export const physicalCardImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469536/main%20website/website-2.0/Physical_Card_template_fqtvws.png";

export const mobileAppImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469526/main%20website/website-2.0/assets_img_lhk66c.png";

export const simplePhysicalCardImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469537/main%20website/website-2.0/physical_card_zxcfbl.png";

export const walletImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469544/main%20website/website-2.0/wallet_p1sl2y.png";

export const transferImg =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469541/main%20website/website-2.0/transfer_o0pcet.png";

export const qaIllustration =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693930714/main%20website/shutterstock_1579952086_p1kuhg.jpg";

export const transferIllustration =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1693930714/main%20website/2107440914-huge_npswtd.jpg";

export const twoPhonesIllustration =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469543/main%20website/website-2.0/two_phones_r6s2fg.png";

export const menuBarLogo =
  "https://res.cloudinary.com/payqin-ltd/image/upload/v1699469534/main%20website/website-2.0/menu_bar_a6ferq.png";
