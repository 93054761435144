import { useEffect } from "react";
import { GreenCheckmark } from "../shared/spinner";
import { t } from "i18next";

export const Completed = () => {
  const deleteHistory = () => {
    localStorage.clear();
  };
  useEffect(() => {
    deleteHistory();
  }, []);
  return (
    <div className="flex flex-col gap-y-5 items-center p-10 justify-center ">
      <GreenCheckmark />
      <h1 className="text-xl">{t("index.completedText")}</h1>
    </div>
  );
};
