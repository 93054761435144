import axios, { AxiosError } from "axios";
import i18next from "i18next";
import { accountService } from "./account.service";

// const devUrl = "http://localhost:8080/api";
const Axios = axios.create({
  baseURL: "https://get-payqin.ew.r.appspot.com/api",
});

Axios.interceptors.request.use(async (request) => {
  request.headers["Accept-Language"] = i18next.language;
  request.headers["mode"] = "no-cors";
  // request.headers["Authorization"] = `Bearer ${token}`;

  request.headers.Authorization = "Bearer " + accountService.getToken();

  return request;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export const httpService = { Axios };
