import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { motion } from "framer-motion";
import { Spinner } from "../shared/spinner";
import { accountService } from "../services/account.service";
import { toast } from "react-toastify";

interface IDeletionData {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  type: string;
  data?: string[];
  operation: string;
}

enum DeletionType {
  Complete = "complete",
  Partial = "partial",
}

interface ICheckboxInput {
  label: string;
  labelValue: string;
  initialValue?: boolean;
  onValueChange?: (newValue: string) => void;
}

export const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkedTotal, setCheckedTotal] = useState<boolean>(false);
  const [checkedPartial, setCheckedPartial] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [queryParam, setQueryParam] = useState<string>("");

  const noChoice = !checkedPartial && !checkedTotal;

  const getQueryParam = () => {
    const ref = new URLSearchParams(location.search);

    const paramValue = ref.get("unsubscribe");

    if (paramValue !== null) {
      setQueryParam(paramValue);
      localStorage.setItem("id", paramValue);

      navigate({ pathname: location.pathname });
    }
  };

  const handleValueChange = (value: string) => {
    if (value.trim() !== "") {
      const isValueSelected = selectedData.includes(value);
      if (isValueSelected) {
        setSelectedData((prevSelectedData) =>
          prevSelectedData.filter((item) => item !== value)
        );
      } else {
        setSelectedData((prevSelectedData) => [...prevSelectedData, value]);
      }
    }
  };

  const handleConfirmDeletion = async ({
    event,
    type,
    operation,
    data,
  }: IDeletionData) => {
    if (checkedPartial && data && data?.length <= 0) {
      setError(t("index.errorField"));

      return;
    }
    setLoading(true);

    event.preventDefault();

    try {
      const resp = await accountService.deleteAccount({
        operation,
        type,
        data,
      });

      if (resp.status == 200) {
        setTimeout(() => {
          setLoading(false);
          setShowModal(!showModal);
          navigate("/operation-completed");
        }, 3000);
      } else {
        setLoading(false);
        toast.error(t("index.operationError"));
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(t("index.generalError"));
    }
  };

  const handleModalState = () => {
    setLoading(true);
    setShowModal(!showModal);
    if (error) {
      setError("");
      setSelectedData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getQueryParam();
  }, [queryParam]);

  return (
    <section className="my-5 mx-5 lg:mx-10">
      <h1 className="text-2xl lg:text-3xl font-bold ">{t("index.welcome")}</h1>
      <p className="text-lg my-3">{t("index.description")}</p>
      <article className="my-4">
        <div className="block">
          <div className="flex items-center gap-x-2">
            <input
              type="checkbox"
              name="first_option"
              id="first_option"
              value={DeletionType.Complete}
              className="h-3 w-3"
              checked={checkedTotal}
              onChange={(e) => {
                setSelectedType(DeletionType.Complete);
                setCheckedTotal(e.target.checked);
              }}
              disabled={checkedPartial}
            />

            <span className="text-xl font-medium">
              {t("index.totalDeleteTitle")}
            </span>
          </div>
          <div className="mx-5 my-2 text-sm">
            <ul>
              <li className="my-1">{t("index.totalDeleteSub")}</li>
            </ul>
          </div>
        </div>

        <div className="block">
          <div className="flex items-center gap-x-2">
            <input
              type="checkbox"
              name="second_option"
              id="second_option"
              value={DeletionType.Partial}
              className="h-3 w-3"
              checked={checkedPartial}
              disabled={checkedTotal}
              onChange={(e) => {
                setSelectedType(DeletionType.Partial);
                setCheckedPartial(e.target.checked);
              }}
            />

            <span className="text-xl font-medium">
              {t("index.partialDeleteTitle")}
            </span>
          </div>
          <div className="mx-5 my-2 text-sm">
            <ul>
              <li className="my-1">{t("index.partialDeleteSub")}</li>
            </ul>
          </div>
        </div>

        <div className="my-10">
          <motion.button
            initial={{ scale: 1 }}
            whileHover={{ scale: noChoice ? 1 : 1.05 }}
            className={`flex items-center gap-x-2 text-white px-3 md:px-6 py-3 ${
              noChoice ? "bg-payqinLightRed" : "bg-payqinRed"
            }  text-center text-xs md:text-sm rounded-full`}
            disabled={noChoice}
            onClick={handleModalState}
          >
            {t("index.deleteButton")}
          </motion.button>
        </div>
      </article>
      {showModal && (
        <div
          onClick={handleModalState}
          className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white p-8 rounded shadow-lg lg:w-[32rem]"
          >
            {checkedTotal ? (
              <>
                <h1 className="text-xl lg:text-2xl font-bold mb-4">
                  {t("index.totalDeleteTitle")}
                </h1>
                <p className="text-lg text-wrap">
                  {t("index.totalDeleteInstructions")}
                </p>
              </>
            ) : (
              <>
                <h1 className="text-xl lg:text-2xl font-bold mb-4">
                  {t("index.partialDeleteTitle")}
                </h1>
                <p className="text-lg font-medium">
                  {t("index.partialDeleteInstructions")}
                </p>

                <div className="mx-2 mt-2 mb-5 text-sm">
                  <CheckBoxInput
                    label={t("index.username")}
                    labelValue={"username"}
                    onValueChange={handleValueChange}
                  />
                  <CheckBoxInput
                    label={t("index.email")}
                    labelValue={"email"}
                    onValueChange={handleValueChange}
                  />
                  <CheckBoxInput
                    label={t("index.phone")}
                    labelValue={"phone"}
                    onValueChange={handleValueChange}
                  />
                  <CheckBoxInput
                    label={t("index.idCard")}
                    labelValue={"idCard"}
                    onValueChange={handleValueChange}
                  />
                  <CheckBoxInput
                    label={t("index.picture")}
                    labelValue={"picture"}
                    onValueChange={handleValueChange}
                  />
                </div>
              </>
            )}

            {error && (
              <div className="my-2">
                <p className="text-payqinRed text-sm">{error}</p>
              </div>
            )}

            <div className="flex items-center gap-x-4 my-6">
              <button
                className={`flex items-center gap-x-2 text-gray-500 px-3 md:px-6 py-3 
                border-gray-500 border bg-white text-center text-xs md:text-sm rounded-full`}
                onClick={handleModalState}
              >
                {t("index.cancelDeletion")}
              </button>

              <motion.button
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                className={`flex items-center gap-x-2 text-white px-3 md:px-6 py-3 bg-payqinRed
                 text-center text-xs md:text-sm rounded-full`}
                onClick={(e) =>
                  handleConfirmDeletion({
                    event: e,
                    type: selectedType,
                    data: selectedData,
                    operation: "delete",
                  })
                }
              >
                {t("index.confirmDeletion")} {loading && <Spinner />}
              </motion.button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const CheckBoxInput: React.FC<ICheckboxInput> = ({
  label,
  labelValue,
  initialValue = false,
  onValueChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialValue);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked ? e.target.value : "";

    setIsChecked(e.target.checked);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <div className="flex items-center gap-x-2 my-2">
      <input
        type="checkbox"
        name="labelValue"
        id="labelValue"
        value={labelValue}
        className="h-3 w-3"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />

      <span className="text-sm">{label}</span>
    </div>
  );
};
