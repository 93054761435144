import { httpService } from "./http.service";

interface IDataRequest {
  operation: string;
  type: string;
  data?: string[];
}

const getToken = () => {
  return localStorage.getItem("id");
};

const deleteAccount = ({ operation, type, data }: IDataRequest) => {
  const body = {
    operation,
    type,
    data,
  };
  return httpService.Axios.post("/users/delete-account", body);
};

export const accountService = {
  deleteAccount,
  getToken,
};
