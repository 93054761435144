import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Home } from "./routes/home";
import { Navbar } from "./shared/header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Completed } from "./routes/completed";

function App() {
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/operation-completed" element={<Completed />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position={"top-center"}
        autoClose={2000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        style={{ marginTop: "2%" }}
      />
    </>
  );
}

export default App;
